import './SearchPage.scss';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Loader} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';
import {useNavigate, useSearchParams} from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import {Filters, SearchBar, Section, SectionItemsHeader} from '@features/search';
import {
    SearchInputModel,
    SearchRequest,
    SearchState,
    SortOption,
    SuggestItem
} from '@features/search/interfaces';
import {useSearchResults} from '@features/search/hooks';
import {SearchContext} from '@features/search/context';
import {NotFound} from '@components/NotFound';
import {ScrollButton} from '@components';
import {cleanSearchValue, getBrandNameByLaximoBrand, getHomeUrl, getSearchUrl} from '@utils';
import useIsTabActive from '@hooks/useIsTabActive';
import {AppContext} from '@app/context';
import {basketStorage} from '@app/basketStorage';

const b = block('search-page');
const stickyElementsTopOffset = 147;

export const SearchPage: React.FC = () => {
    const navigateTo = useNavigate();

    const {emit} = useContext(AppContext);
    const isTabActive = useIsTabActive();
    const isFirstRef = useRef(true);

    const [searchParams] = useSearchParams();
    const textParam = cleanSearchValue(searchParams.get('text'));
    const [searchText, setSearchText] = useState<SearchInputModel>({
        originalValue: textParam,
        cleanedValue: textParam,
        needToSuggestBrands: false
    });
    const brandParam = decodeURIComponent(searchParams.get('brand') || '');
    const isLaximoSource = searchParams.get('source') === 'laximo';
    if (isLaximoSource && brandParam) {
        const brandForSearch = getBrandNameByLaximoBrand(brandParam);
        if (brandForSearch) window.location.href = getSearchUrl(textParam, brandForSearch, true);
        else window.location.href = getHomeUrl(textParam, true);
    }

    const [searchBrand, setSearchBrand] = useState(brandParam); // setSearchBrand
    const [useAnalogs, setUseAnalogs] = useState(!(searchParams.get('useAnalogs') === 'false'));
    const [
        results,
        searchState,
        sortOptions,
        filters,
        showClearFilters,
        searchHistory,
        asyncSearchModel,
        handleFiltersChanged,
        handleSortOptionsChanged
    ] = useSearchResults({searchText, searchBrand, useAnalogs, setSearchText});

    const [suggestItems, setSuggestItems] = useState<SuggestItem[]>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setSearchBrand(decodeURIComponent(searchParams.get('brand') || ''));
        setSearchText({
            originalValue: searchParams.get('text') || '',
            cleanedValue: searchParams.get('text') || '',
            needToSuggestBrands: false
        });
        setUseAnalogs(!(searchParams.get('useAnalogs') === 'false'));
    }, [searchParams]);

    function handleInputChange(value: SearchInputModel) {
        console.log(`SearchPage::handleInputChange value: ${JSON.stringify(value)}`);
        setSearchBrand('');
        setSearchText(value);
        if (searchText.cleanedValue === value.cleanedValue) return;
        setSuggestItems([]);
    }

    function handleSearchClick() {
        if (searchText?.cleanedValue && suggestItems.length === 1) {
            navigateTo(getSearchUrl(searchText.cleanedValue, suggestItems[0].name, useAnalogs));
            return;
        }
        if (searchText?.cleanedValue) {
            navigateTo(getHomeUrl(searchText.cleanedValue, useAnalogs));
            return;
        }
    }

    function handleBrandSelected(brand: SuggestItem) {
        if (searchText?.cleanedValue) {
            navigateTo(getSearchUrl(searchText.cleanedValue, brand.name, useAnalogs));
            setSearchBrand(brand.name);
        }
    }

    function handleSearchHistorySelected(searchHistory: SearchRequest) {
        navigateTo(getSearchUrl(searchHistory.article, searchHistory.brand, useAnalogs));
        setSearchText({
            originalValue: searchHistory.article,
            cleanedValue: searchHistory.article,
            needToSuggestBrands: false
        });
        setSearchBrand(searchHistory.brand);
    }

    function handleSortChanged(sortOptions: SortOption[]) {
        if (handleSortOptionsChanged) handleSortOptionsChanged(sortOptions);
    }

    useEffect(() => {
        if (isTabActive) {
            if (isFirstRef.current) {
                isFirstRef.current = false;
            }

            const basket = basketStorage.get();

            if (basket) {
                emit('updateBasket', {basket});
            }
        }
    }, [isTabActive]);

    return (
        <SearchContext.Provider value={asyncSearchModel}>
            <div className={b()}>
                <Filters filters={filters} onFiltersChange={handleFiltersChanged} />
                <div className={b('results-panel')}>
                    <div className={b('header-background')}></div>
                    <StickyBox
                        className={b('search-bar-sticky-box')}
                        offsetTop={stickyElementsTopOffset}
                    >
                        <SearchBar
                            searchText={searchText}
                            searchBrand={searchBrand}
                            useAnalogs={useAnalogs}
                            onSearchClick={handleSearchClick}
                            onInputChange={handleInputChange}
                            onBrandSelected={handleBrandSelected}
                            onBrandSuggestionsChanged={(items) => setSuggestItems(items)}
                            onUseAnalogsChanged={(value) => setUseAnalogs(value)}
                            searchHistory={searchHistory}
                            onSearchHistorySelected={handleSearchHistorySelected}
                        />
                    </StickyBox>
                    <div
                        className={b(
                            'results-searching',
                            searchState === SearchState.Searching ? '' : 'hidden'
                        )}
                    >
                        <Loader size="l"></Loader>
                    </div>
                    <div
                        className={b(
                            'results-not-found',
                            searchState === SearchState.Empty ? '' : 'hidden'
                        )}
                    >
                        <NotFound showClearFilters={showClearFilters}></NotFound>
                    </div>
                    <div
                        className={b(
                            'results-found',
                            searchState === SearchState.Found ? '' : 'hidden'
                        )}
                    >
                        <SectionItemsHeader
                            sortOptions={sortOptions}
                            onSortChanged={handleSortChanged}
                        />
                        <Section
                            title="Результат поиска запчасти"
                            isTopLevel
                            items={results.searched}
                        ></Section>
                        <Section
                            title="Оригинальные замены"
                            items={results.original}
                        ></Section>
                        <Section
                            title="Аналоги"
                            items={results.analogs}
                        ></Section>
                    </div>
                </div>
                <ScrollButton />
            </div>
        </SearchContext.Provider>
    );
};
