import {AppState} from './context';
import {userStorage} from '@app/user';

const ORDERS_SETTINGS_KEY = `ordersSettings`;

class OrdersSettingsStorage {
    getUserLogin = (): string => {
        return `${ORDERS_SETTINGS_KEY}_${userStorage.get()?.login}` || ORDERS_SETTINGS_KEY
    }

    set = (config: AppState['orders']['config']) =>
        localStorage.setItem(this.getUserLogin(), JSON.stringify(config));

    get = (): AppState['orders']['config'] | null => {
        const storedConfig = localStorage.getItem(this.getUserLogin());
        return storedConfig ? JSON.parse(storedConfig) : null;
    };

    clear = () => localStorage.removeItem(this.getUserLogin());
}

export const ordersSettingsStorage = new OrdersSettingsStorage();
