import React, {ChangeEvent, useContext} from 'react';
import {useMediaQuery} from 'react-responsive';
import block from 'bem-cn-lite';
import {
    ArticleInfo,
    DeliveryDate,
    DeliveryDays,
    Price,
    ProposalBadges,
    Quantity,
    QuantityInput,
    Rating
} from '@components';
import {Checkbox, Text} from '@gravity-ui/uikit';
import {formatDate} from '@utils';
import {AuxControls} from './components';
import {useBasket} from '@features/basket/hooks/useBasket';
import {BasketItemModel} from '@features/basket/interfaces';
import {AppContext} from 'app/context';
import {getAdjustedPrice} from '@utils/getAdjustedPrice';

const b = block('items');

interface ItemsProps {
    itemsUnavailableOccurred: boolean;
    itemsCntExceedsQuantityOccurred: boolean;
    items: BasketItemModel[];
}

export const Items: React.FC<ItemsProps> = ({
    itemsUnavailableOccurred,
    itemsCntExceedsQuantityOccurred,
    items
}) => {
    const {state} = useContext(AppContext);
    const isSmallScreen = useMediaQuery({query: '(max-width: 1280px)'});

    const {handleItemOutCheckChange} = useBasket();

    return (
        <div className={b()}>
            {items.map((item) => {
                const [dateStr, timeStr] = formatDate(item.createdAt, [
                    'DD.MM.YY',
                    'HH:mm'
                ]);

                let errorText = '';

                if (item.status === 'unavailable') {
                    errorText = `К заказу было ${item.itemsCnt} шт. Осталось 0 шт.`;
                } else if (itemsUnavailableOccurred && itemsCntExceedsQuantityOccurred) {
                    errorText = `К заказу было ${item.itemsCnt} шт. Осталось ${item.quantity} шт.`;
                } else if (itemsUnavailableOccurred && !itemsCntExceedsQuantityOccurred) {
                    if (item.quantityChangedToZero) {
                        errorText = 'Товар закончился';
                    }
                } else if (!itemsUnavailableOccurred && itemsCntExceedsQuantityOccurred) {
                    if (item.quantityDecreased) {
                        errorText = `К заказу было ${item.itemsCnt} шт. Осталось ${item.quantity} шт.`;
                    }
                }

                const quantityValue =
                    item.status === 'unavailable'
                        ? 0
                        : item.quantity > 0
                          ? Math.min(item.itemsCnt, item.quantity)
                          : 0;

                const adjustedPrice = getAdjustedPrice(
                    item.price,
                    state.priceLevelSettings,
                    'basket'
                );
                const adjustedNewPrice = item.newPrice
                    ? getAdjustedPrice(item.newPrice, state.priceLevelSettings, 'basket')
                    : undefined;

                const adjustedAmount = getAdjustedPrice(
                    item.amount ?? 0,
                    state.priceLevelSettings,
                    'basket'
                );

                return (
                    <div key={item.id} className={b('row')}>
                        <div className={b('item', b('is-selected'))}>
                            <Checkbox
                                size={'l'}
                                checked={item.checked}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    handleItemOutCheckChange(item.id, event.target.checked)
                                }
                            ></Checkbox>
                        </div>

                        {!isSmallScreen && (
                            <div className={b('item', b('date'))}>
                                <Text variant={'body-2'}>{dateStr}</Text>
                                <Text variant={'body-2'} className={b('date', 'time')}>
                                    {timeStr}
                                </Text>
                            </div>
                        )}

                        <ArticleInfo
                            className={b('item', b('article'))}
                            header={{
                                article: item.article,
                                articleName: item.articleName,
                                brandName: item.brandName
                            }}
                        />

                        <ProposalBadges
                            className={b('item', b('icons'))}
                            isOfficialDealer={item.isOfficialDealer}
                            isReturnPossible={item.isReturnPossible}
                        />

                        <Rating value={item.rating} priceList={item.priceList} className={b('item', b('rating'))} />

                        {!isSmallScreen && (
                            <Quantity
                                className={b('item', b('quantity'))}
                                value={item.quantity}
                                status={item.status}
                            />
                        )}

                        <DeliveryDays
                            value={item.deliveryDays}
                            className={b('item', b('delivery-days'))}
                        />

                        <DeliveryDate
                            value={item.deliveryDate}
                            className={b('item', b('delivery-date'))}
                        />

                        <Price
                            value={adjustedPrice}
                            newValue={adjustedNewPrice}
                            className={b('item', b('price'))}
                        />

                        <Price value={adjustedAmount} className={b('item', b('amount'))} />

                        <QuantityInput
                            value={quantityValue}
                            multiplicity={item.multiplicity}
                            maxQuantity={item.quantity}
                            onChange={() => {}}
                            className={b('item', b('quantity-input'))}
                            errorText={errorText}
                            disabled={item.status === 'unavailable'}
                        />

                        <AuxControls item={item} />
                    </div>
                );
            })}
        </div>
    );
};
