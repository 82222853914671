import './PersonalAccountLayout.scss';
import React, {useContext, useEffect} from 'react';
import block from 'bem-cn-lite';
import {Outlet} from 'react-router-dom';
import {FooterBlock, Header} from '@components';
import {CApi as userGwApi} from '@services/api';
import {AppContext} from 'app/context';
import {priceLevelSettingsStorage} from '@app/priceLevelSettings';
import useIsTabActive from '@hooks/useIsTabActive';
import {tokenStorage} from '@app/token';
import {getBasket} from '@features/basket/services';
import {getBasketForStateUpdate} from '@features/basket/services/getBasketForStateUpdate';
import {isBasketLocation} from '@utils';
import {ordersFiltersStorage} from '@app/ordersFiltersStorage';
import {ordersSettingsStorage} from '@app/ordersSettingsStorage';

const b = block('personal-account-layout');

export const PersonalAccountLayout: React.FC = () => {
    const {state, emit} = useContext(AppContext);
    const isTabActive = useIsTabActive();
    const storedOrdersFilters = ordersFiltersStorage.get();
    const storedOrdersSettings = ordersSettingsStorage.get();

    useEffect(() => {
        if (!isTabActive) return;
        loadClientDetails();
    }, [isTabActive]);

    useEffect(() => {
        loadClientDetails();

        // Если загружаем не страницу корзины, то обновим данные для шапки
        // На странице корзины этого делать не нужно, там уже есть обновление
        if (!isBasketLocation()) {
            getBasket()
                .then(({items, itemsOut}) => {
                    const basketState = getBasketForStateUpdate(items, itemsOut);
                    emit('updateBasket', {
                        basket: {
                            ...basketState,
                            items: items,
                            itemsOut: itemsOut
                        }
                    });
                });
        }

        //Обновим контекст, загрузим сохранены настройки из стора
        //Восстанавливаем состояние колонок в таблицы на странице заказов
        if (storedOrdersSettings) {
            emit('updateOrdersConfig', {
                config: storedOrdersSettings,
            });
        }

        //Восстанавливаем состояние фильтров на странице заказов
        if (storedOrdersFilters) {
            emit('updateOrders', {
                ...state,
                orders: {
                    filters: storedOrdersFilters
                }
            });
        }
    }, []);

    function emitLoginEventIfTokenChanged() {
        const lsToken = tokenStorage.get();
        if (state.token !== lsToken) {
            window.location.reload();
        }
    }

    function loadClientDetails() {
        emitLoginEventIfTokenChanged();

        if (state?.user?.login) {
            userGwApi()
                .client.clientDetail(state.user.client)
                .then((response) => {
                    emit('updateClientDetails', {clientDetails: response.data});
                    emit('updatePriceLevelSettings', {
                        settings: {
                            enabled: priceLevelSettingsStorage.get()?.enabled || false,
                            markupPercentage: response.data.settings.visibleExtraCharge,
                            displayInSearchResults: response.data.settings.visibleExtraChargeView.some(item => item === 'suggest'),
                            displayInBasket: response.data.settings.visibleExtraChargeView.some(item => item === 'basket')
                        }
                    });
                })
                .catch((error) => {
                    console.error('Ошибка при получении данных клиента:', error);
                });
        }
    }

    return (
        <div className={b()}>
            <Header />

            <div className={b('content')}>
                <Outlet />
            </div>

            <FooterBlock isMinimal />
        </div>
    );
};
