import {FiltersModel} from '@features/orders/interfaces';
import {userStorage} from './user';

export interface OrdersFilters {
    filters: FiltersModel;
}

const ORDERS_FILTERS_KEY = 'ordersFilters';

class OrdersFiltersStorage {
    getUserLogin = (): string => {
        return `${ORDERS_FILTERS_KEY}_${userStorage.get()?.login}` || ORDERS_FILTERS_KEY
    }

    set = (filters: FiltersModel) =>
        localStorage.setItem(this.getUserLogin(), JSON.stringify(filters));

    get = (): FiltersModel | null => {
        const filters = localStorage.getItem(this.getUserLogin());
        return filters ? JSON.parse(filters) : null;
    };

    clear = () => localStorage.removeItem(this.getUserLogin());
}

export const ordersFiltersStorage = new OrdersFiltersStorage();
