import './AuxControls.scss';
import {ArrowUturnCwLeft, Magnifier} from '@gravity-ui/icons';
import {Button, Text, Tooltip} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';
import {OrderItemModel} from '@features/orders/interfaces';
import {getSearchUrl} from '@utils';
import {devServerName} from 'settings';

const b = block('aux-controls-orders');

interface AuxControlsProps {
    isReturnPossible: boolean;
    item: OrderItemModel;
}

export const AuxControls: React.FC<AuxControlsProps> = ({isReturnPossible, item}) => {
    const hostName = window.location.hostname;

    const handleSearchClick = () => {
        const url = getSearchUrl(item.article, item.brandName, false);
        window.open(url, '_blank');
    };

    return (
        <div className={b()}>
            <Tooltip
                content={
                    <Text variant={'body-1'} className={b('tooltip-content')}>
                        Новый поиск по артикулу
                    </Text>
                }
                contentClassName={b('tooltip')}
                className={b('tooltip-container')}
                placement="top-end"
                openDelay={0}
                closeDelay={0}
            >
                <div>
                    <Button view={'normal'} className={b('button')} onClick={handleSearchClick}>
                        <Magnifier />
                    </Button>
                </div>
            </Tooltip>
            {
                devServerName.some(name => name === hostName) ?
                    isReturnPossible ? (
                        <Tooltip
                            content={
                                <Text variant={'body-1'} className={b('tooltip-content')}>
                                    Оформление возврата
                                </Text>
                            }
                            contentClassName={b('tooltip')}
                            className={b('tooltip-container')}
                            placement="top-end"
                            openDelay={0}
                            closeDelay={0}
                        >
                            <div>
                                <Button view={'outlined-danger'} className={b('button')}>
                                    <ArrowUturnCwLeft />
                                </Button>
                            </div>
                        </Tooltip>
                    ) : (
                        <Tooltip
                            content={
                                <Text variant={'body-1'} className={b('tooltip-content')}>
                                    Возврат невозможен
                                </Text>
                            }
                            contentClassName={b('tooltip')}
                            className={b('tooltip-container')}
                            placement="top-end"
                            openDelay={0}
                            closeDelay={0}
                        >
                            <div>
                                <Button view={'outlined-danger'} className={b('button')} disabled={true}>
                                    <ArrowUturnCwLeft />
                                </Button>
                            </div>
                        </Tooltip>
                    )
                    : null
            }
        </div>
    );
};
