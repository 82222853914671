import {AppContext} from 'app/context';
import {useContext, useCallback} from 'react';
import {BasketItemModel} from '../interfaces';
import {CApi as userGwApi} from '@services/api';
import {BasketPatch} from '@services/user-gw';
import {getBasketChecked} from '../services';

export const useBasket = () => {
    const {state, emit} = useContext(AppContext);
    const {basket} = state;

    const handleItemQuantityChange = useCallback(
        (id: string, newQuantity: number) => {
            // Обновляем количество и сумму для нужного элемента в корзине
            const updatedItems = basket.items.map((item) => {
                if (item.id === id) {
                    const amount = item.newPrice
                        ? item.newPrice * newQuantity
                        : item.price * newQuantity;
                    return {
                        ...item,
                        itemsCnt: newQuantity,
                        amount: amount
                    };
                }
                return item;
            });

            // Находим текущий комментарий для этого товара
            const currentComment = basket.items.find((item) => item.id === id)?.comment || '';

            // Формируем данные для обновления на сервере (количество и комментарий)
            const updatedItem = {
                itemsCnt: newQuantity,
                comment: currentComment // Сохраняем текущий комментарий
            };

            emit('updateBasket', {
                basket: {
                    items: updatedItems // Обновленные товары
                }
            });

            // Отправляем запрос на сервер для обновления количества и комментария
            userGwApi()
                .basket.basketPartialUpdate({[id]: updatedItem})
                .then(() => {
                    console.log('Количество и комментарий успешно обновлены на сервере');
                })
                .catch((error) => {
                    console.error(
                        'Ошибка при обновлении количества и комментария на сервере:',
                        error
                    );
                });
        },
        [basket.items, emit]
    );

    const handleItemCheckChange = useCallback(
        (id: string, checked: boolean) => {
            const updatedItems = basket.items.map((item) => {
                // Возвращаем новый объект для каждого измененного элемента, чтобы избежать мутации старого состояния
                if (item.id === id) {
                    return {...item, checked};
                }
                return item;
            });

            emit('updateBasket', {
                basket: {
                    items: updatedItems
                }
            });
        },
        [basket.items, emit]
    );

    const handleItemOutCheckChange = useCallback(
        (id: string, checked: boolean) => {
            const updatedItemsOut = basket.itemsOut.map((item) =>
                item.id === id ? {...item, checked} : item
            );

            emit('updateBasket', {
                basket: {
                    itemsOut: updatedItemsOut
                }
            });
        },
        [basket.itemsOut, emit]
    );

    const handleSelectAll = useCallback(
        (checked: boolean) => {
            const updatedItems = basket.items.map((item: BasketItemModel) => ({
                ...item,
                checked
            }));

            emit('updateBasket', {
                basket: {
                    items: updatedItems
                }
            });
        },
        [basket.items, emit]
    );

    const handleSelectAllItemsOut = useCallback(
        (checked: boolean) => {
            const updatedItemsOut = basket.itemsOut.map((item) => ({
                ...item,
                checked
            }));

            emit('updateBasket', {
                basket: {
                    itemsOut: updatedItemsOut
                }
            });
        },
        [basket.itemsOut, emit]
    );

    const handleDeleteSelected = useCallback(() => {
        const selectedItems: BasketPatch = basket.items.reduce((acc, item) => {
            if (item.checked) {
                acc[item.id] = {itemsCnt: 0};
            }
            return acc;
        }, {} as BasketPatch);

        // Проверяем, есть ли выбранные элементы
        if (Object.keys(selectedItems).length === 0) {
            console.log('Нет выбранных элементов для удаления');
            return;
        }

        userGwApi()
            .basket.basketPartialUpdate(selectedItems)
            .then(() => {
                // Если запрос успешен, обновляем глобальное состояние корзины
                const updatedItems = basket.items.filter((item) => !item.checked);

                const {allChecked} = getBasketChecked(updatedItems, []);

                emit('updateBasket', {
                    basket: {
                        items: updatedItems,
                        allChecked
                    }
                });

                emit('updateCounter', {
                    counterName: 'basketItems',
                    value: updatedItems.length
                });
            })
            .catch((error) => {
                console.error('Ошибка при удалении элементов:', error);
            });
    }, [emit, basket.items]);

    const handleDeleteItem = useCallback(
        (id: string) => {
            const itemToDelete: BasketPatch = {
                [id]: {itemsCnt: 0}
            };

            userGwApi()
                .basket.basketPartialUpdate(itemToDelete)
                .then(() => {
                    // Если запрос успешен, обновляем глобальное состояние корзины
                    const updatedItems = basket.items.filter((item) => item.id !== id);

                    emit('updateBasket', {
                        basket: {
                            items: updatedItems // Обновленные товары после удаления
                        }
                    });

                    emit('updateCounter', {
                        counterName: 'basketItems',
                        value: updatedItems.length
                    });
                })
                .catch((error) => {
                    console.error('Ошибка при удалении элемента:', error);
                });
        },
        [emit, basket.items]
    );

    const handleDeleteItemOut = useCallback(
        (id: string) => {
            const itemToDelete: BasketPatch = {
                [id]: {itemsCnt: 0}
            };

            userGwApi()
                .basket.basketPartialUpdate(itemToDelete)
                .then(() => {
                    // Если запрос успешен, обновляем глобальное состояние itemsOut
                    const updatedItemsOut = basket.itemsOut.filter((item) => item.id !== id);

                    emit('updateBasket', {
                        basket: {
                            itemsOut: updatedItemsOut
                        }
                    });

                    emit('updateCounter', {
                        counterName: 'basketItems',
                        value: updatedItemsOut.length
                    });
                })
                .catch((error) => {
                    console.error('Ошибка при удалении элемента:', error);
                });
        },
        [emit, basket.itemsOut]
    );

    const refreshBasketItems = useCallback(async () => {
        const itemIds = [...basket.items, ...basket.itemsOut].map((item) => item.id);

        try {
            const response = await userGwApi().basket.refreshCreate({
                items: itemIds
            });

            const refreshedItems = response.data.items;
            console.log('Актуализация корзины завершена:', refreshedItems);

            let priceChanged = false;
            let quantityChanged = false;
            let itemsUnavailable = false;
            let itemsCntExceedsQuantity = false;
            let itemsMovedToUnavailable: string[] = [];
            let priceIncreased = false;
            let priceDecreased = false;

            const availableItemIdsBefore = new Set(basket.items.map((item) => item.id));

            const updatedItems = [...basket.items, ...basket.itemsOut].map((item) => {
                const refreshedItem = refreshedItems[item.id];
                if (refreshedItem) {
                    const prevPrice = item.price;
                    const newPrice = refreshedItem.new.price;
                    const prevQuantity = item.quantity;
                    const newQuantity = refreshedItem.new.quantity;
                    const itemStatus = refreshedItem.new.status;

                    const updatedItem = {...item};

                    if (prevPrice !== newPrice) {
                        priceChanged = true;
                        updatedItem.oldPrice = prevPrice;
                        updatedItem.price = newPrice;

                        if (newPrice > prevPrice) {
                            priceIncreased = true;
                        } else if (newPrice < prevPrice) {
                            priceDecreased = true;
                        }
                    }

                    if (prevQuantity !== newQuantity) {
                        quantityChanged = true;

                        if (prevQuantity > 0 && newQuantity === 0) {
                            updatedItem.quantityChangedToZero = true;
                        } else if (prevQuantity > newQuantity && newQuantity > 0) {
                            if (item.itemsCnt > newQuantity) {
                                itemsCntExceedsQuantity = true;
                                updatedItem.quantityDecreased = true;
                            }
                        }
                    }

                    if (itemStatus === 'unavailable') {
                        updatedItem.quantity = 0;
                        updatedItem.quantityChangedToZero = true;
                    }

                    const wasAvailable = availableItemIdsBefore.has(item.id);
                    const isNowUnavailable =
                        updatedItem.quantity === 0 || updatedItem.status === 'unavailable';

                    if (wasAvailable && isNowUnavailable) {
                        updatedItem.checked = false;
                        itemsMovedToUnavailable.push(item.id);
                    }

                    return {
                        ...updatedItem,
                        quantity: newQuantity,
                        previousQuantity: prevQuantity,
                        status: itemStatus
                    };
                } else {
                    return item;
                }
            });

            itemsUnavailable = itemsMovedToUnavailable.length > 0;

            const availableItems = updatedItems.filter(
                (item) => item.quantity > 0 && item.status !== 'unavailable'
            );
            const unavailableItems = updatedItems.filter(
                (item) => item.quantity === 0 || item.status === 'unavailable'
            );

            emit('updateBasket', {
                basket: {
                    items: availableItems,
                    itemsOut: unavailableItems
                }
            });

            return {
                priceChanged,
                quantityChanged,
                itemsUnavailable,
                itemsCntExceedsQuantity,
                itemsMovedToUnavailable,
                priceIncreased,
                priceDecreased
            };
        } catch (error) {
            console.error('Ошибка при актуализации корзины:', error);
            throw error;
        }
    }, [emit, basket.items, basket.itemsOut]);

    return {
        ...basket,
        handleItemQuantityChange,
        handleItemCheckChange,
        handleItemOutCheckChange,
        handleSelectAll,
        handleSelectAllItemsOut,
        handleDeleteSelected,
        handleDeleteItem,
        handleDeleteItemOut,
        refreshBasketItems
    };
};
