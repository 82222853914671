import React, {useState} from 'react';
import {Checkbox, Text, TextInput} from '@gravity-ui/uikit';
import {CaretDown, CaretUp, ChevronDown} from '@gravity-ui/icons';
import {FilterBrandModel} from '@features/search/interfaces';
import {formatCurrencyRub} from '@utils';

import './BrandFilter.scss';
import block from 'bem-cn-lite';

const b = block('brand-filter');

const MAX_BRANDS_TO_SHOW = 5;

interface BrandSortOption {
    direction: 'asc' | 'desc';
    isActive: boolean;
    property: 'name' | 'price';
    defaultDirection: 'asc' | 'desc';
}

export interface BrandFilterProps {
    brands: FilterBrandModel[];
    onBrandsChange?: (brands: FilterBrandModel[]) => void;
}

export const BrandFilter: React.FC<BrandFilterProps> = ({brands, onBrandsChange}) => {
    const isShowMoreVisible = brands.length > MAX_BRANDS_TO_SHOW;
    const [showMoreToggled, setShowMoreToggled] = useState(false);

    const [brandNameFilterValue, setBrandNameFilterValue] = useState<string>();

    const [brandSortOptions, setBrandSortOptions] = useState<BrandSortOption[]>([
        {
            direction: 'asc',
            isActive: true,
            property: 'name',
            defaultDirection: 'asc'
        },
        {
            direction: 'asc',
            isActive: false,
            property: 'price',
            defaultDirection: 'asc'
        }
    ]);
    const nameSort = brandSortOptions.filter((x) => x.property === 'name')[0];
    const priceSort = brandSortOptions.filter((x) => x.property === 'price')[0];
    const activeSorts = brandSortOptions.filter((x) => x.isActive);
    const activeSort = activeSorts.length === 1 ? activeSorts[0] : brandSortOptions[0];

    const brandsToShow = getFilteredAndSortedBrands();

    function getFilteredAndSortedBrands() {
        const filteredBrands = brandNameFilterValue && brandNameFilterValue.length > 0
            ? brands.filter((brand) => brand.brandName.toLowerCase().includes(brandNameFilterValue.toLowerCase()))
            : brands;

        const sortedBrands = filteredBrands.slice().sort((a, b) => {
            if (brandNameFilterValue && brandNameFilterValue.length > 0) {
                const nameA = a.brandName.toLowerCase();
                const nameB = b.brandName.toLowerCase();
                const filterValue = brandNameFilterValue.toLowerCase();

                // Sort exact matches first
                const isExactA = nameA === filterValue;
                const isExactB = nameB === filterValue;

                if (isExactA && !isExactB) return -1; // A is an exact match
                if (!isExactA && isExactB) return 1;  // B is an exact match
            }

            if (activeSort.property === 'name')
                return activeSort.direction === 'asc'
                    ? a.brandName.localeCompare(b.brandName)
                    : b.brandName.localeCompare(a.brandName);
            else if (activeSort.property === 'price')
                return activeSort.direction === 'asc'
                    ? a.minPrice - b.minPrice
                    : b.minPrice - a.minPrice;
            return 0;
        });

        return (sortedBrands.length <= MAX_BRANDS_TO_SHOW) || showMoreToggled
            ? sortedBrands
            : sortedBrands.slice(0, MAX_BRANDS_TO_SHOW);
    }

    function onBrandSelectedChange(brandName: string, value: boolean) {
        let brandsWithCheck = brands.map((brand) => (brand.brandName === brandName ? {
            ...brand,
            isSelected: value
        } : brand));
        if (onBrandsChange) {
            onBrandsChange(brandsWithCheck);
        }
    }

    function handleSortClick(sortOption: BrandSortOption) {
        const getSortDirection = (so: BrandSortOption) => {
            return !so.isActive ? so.defaultDirection : so.direction === 'asc' ? 'desc' : 'asc';
        };
        const newSortOptions = brandSortOptions.map((x) => {
            return x.property !== sortOption.property
                ? {...x, direction: 'asc', isActive: false}
                : {...x, direction: getSortDirection(sortOption), isActive: true};
        }) as BrandSortOption[];
        setBrandSortOptions(newSortOptions);
    }

    function handleShowMoreClick() {
        setShowMoreToggled((prev) => !prev);
    }

    return (
        <div className={b()}>
            <TextInput
                className={b('find-brand', {hidden: !showMoreToggled})}
                placeholder="Поиск по брендам"
                hasClear={true}
                onUpdate={(value) => setBrandNameFilterValue(value)}
            />
            <div className={b('header')}>
                <div className={b('header-column')} onClick={() => handleSortClick(nameSort)}>
                    <Text>Бренд</Text>
                    <div className={b('header-sorter')}>
                        {nameSort.direction === 'asc' ? (
                            <CaretUp className={b('caret', {active: nameSort.isActive})} />
                        ) : (
                            <CaretDown className={b('caret', {active: nameSort.isActive})} />
                        )}
                    </div>
                </div>
                <div style={{flexGrow: 1}}></div>
                <div className={b('header-column')} onClick={() => handleSortClick(priceSort)}>
                    <Text>Цена</Text>
                    <div className={b('header-sorter')}>

                        {priceSort.direction === 'asc' ? (
                            <CaretUp className={b('caret', {active: priceSort.isActive})} />
                        ) : (
                            <CaretDown className={b('caret', {active: priceSort.isActive})} />
                        )}
                    </div>
                </div>
            </div>
            <div className={b('brand-list', {scroll: showMoreToggled})}>
                    {brandsToShow.map((brand) => {
                    return (
                        <div key={brand.brandName} className={b('brand-row')}>
                            <Checkbox
                                className={b('brand-name')}
                                checked={brand.isSelected}
                                onChange={(e) =>
                                    onBrandSelectedChange(brand.brandName, e.target.checked)
                                }
                            >
                                {brand.brandName}
                            </Checkbox>
                            <Text className={b('brand-min-price')}>от&nbsp;{formatCurrencyRub(brand.minPrice)}</Text>
                        </div>
                    );
                })
                }
            </div>
            <div className={b('footer', {hidden: !isShowMoreVisible})} onClick={handleShowMoreClick}>
                <Text>{showMoreToggled ? 'Свернуть' : 'Показать все'}</Text>
                <ChevronDown className={b('icon', {rotation: showMoreToggled})}></ChevronDown>
            </div>
        </div>
    );
};