import {FC, RefObject, useEffect, useState} from 'react';
import {Button} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';
import {ArrowDown, ArrowUp} from '@gravity-ui/icons';
import {useLocation} from 'react-router-dom';

import './ScrollButton.scss';

const b = block('scroll-button');

interface ScrollBtnProps {
    targetRef?: RefObject<HTMLDivElement>;
    twinDirection?: boolean;
}

//сколько прокрутить экранов чтобы появилась кнопка
const multiplierScroll = 2;

export const ScrollButton: FC<ScrollBtnProps> = ({targetRef, twinDirection}) => {
    const {scrollHeight, clientHeight} = document.documentElement;
    const [hiddenScroll, setHiddenScroll] = useState(true);
    const [direction, setDirection] = useState<'down' | 'up'>('up');
    const location = useLocation();

    //скрол при клике на кнопку
    function handleClick() {
        if (twinDirection && targetRef && direction === 'down') {
            targetRef.current?.scrollIntoView({behavior: 'smooth', block: twinDirection ? 'end' : 'start'});
            setDirection('up');
        } else {
            window.scrollBy(0, -window.scrollY);
            location.pathname === '/basket' && setDirection('down');
        }
    }

    //показывать скрывать кнопку при скроле
    const handleScroll = () => {

        if (!twinDirection) {
            if (window.scrollY >= window.innerHeight * multiplierScroll) {
                setHiddenScroll(false);
            } else {
                setHiddenScroll(true);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (scrollHeight < clientHeight * 1.15) {
            setHiddenScroll(true);
        } else if (location.pathname === '/basket' && scrollHeight > clientHeight) {
            setHiddenScroll(false);
            setDirection('down');
        }
    }, [scrollHeight]);

    return (
        <Button
            onClick={handleClick}
            view="raised"
            className={b({hidden: hiddenScroll})}
        >
            {
                direction === 'up' ?
                    <ArrowUp />
                    :
                    <ArrowDown />
            }
        </Button>
    );
};