import './HomePage.scss';
import React, {useContext, useEffect, useRef, useState} from 'react';
import block from 'bem-cn-lite';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ChevronDown} from '@gravity-ui/icons';
import {SearchBar} from '@features/search';
import {useSearchHistory} from '@features/search/hooks';
import {SearchInputModel, SearchRequest, SuggestItem} from '@features/search/interfaces';
import {CApi as userGwApi} from '@services/api';
import {AppContext} from 'app/context';
import {NotFound} from '@components';
import {Link, Loader} from '@gravity-ui/uikit';
import {getHomeUrl, getSearchUrl} from '@utils';

const b = block('home-page');

export const HomePage: React.FC = () => {
    const {state, emit} = useContext(AppContext);
    const [searchParams] = useSearchParams();
    const [searchText, setSearchText] = useState<SearchInputModel>({
        originalValue: searchParams.get('text') || '',
        cleanedValue: searchParams.get('text') || '',
        needToSuggestBrands: false
    });
    const [searchBrand, setSearchBrand] = useState(''); // setSearchBrand
    const [useAnalogs, setUseAnalogs] = useState(!(searchParams.get('useAnalogs') === 'false'));

    const [suggestItems, setSuggestItems] = useState<SuggestItem[]>([]);
    const [showBrandSuggestions, setShowBrandSuggestions] = useState(false);
    const [searchHistory] = useSearchHistory();
    const isFirstSearch = useRef(true);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // TODO Сделал быстрый хак, чтобы показать список брендов при открытии страницы, надо переделать по-другому или хотя бы вынести работу с подсказками в отдельный сервис
    const [doFirstSearch, setDoFirstSearch] = useState(!!searchParams.get('text'));
    useEffect(() => {
        if (doFirstSearch && isFirstSearch.current) {
            setIsLoading(true);
            userGwApi()
                .suggestBrand.suggestBrandList({
                    article: searchText.cleanedValue
                })
                .then((r) => {
                    if (r.data.items.length === 1) {
                        goToSearch(searchText.cleanedValue, r.data.items[0].name, useAnalogs);
                    }
                    setSuggestItems(r.data.items as SuggestItem[]);
                    // setSuggestItems(r.data.items.sort((a, b) => a.name.localeCompare(b.name)) as SuggestItem[]);
                    setShowBrandSuggestions(true);
                    emit('toggleBrandSearch', {active: r.data.items.length > 0});
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                })
                .finally(() => setIsLoading(false));
            isFirstSearch.current = false;
            setDoFirstSearch(false);
        }
    }, [doFirstSearch]);

    const navigateTo = useNavigate();

    function startSearch() {
        if (searchText?.cleanedValue) {
            if (suggestItems.length === 1) {
                goToSearch(searchText.cleanedValue, suggestItems[0].name, useAnalogs);
            } else if (searchText?.cleanedValue) {
                navigateTo(getHomeUrl(searchText.cleanedValue, useAnalogs));
                setSearchText({
                    originalValue: searchText.cleanedValue,
                    cleanedValue: searchText.cleanedValue,
                    needToSuggestBrands: false
                });
                showBrands();
            }
            if (searchBrand) {
                goToSearch(searchText.cleanedValue, searchBrand, useAnalogs);
            }
        }
    }

    function goToSearch(textParam: string, brandParam: string, useAnalogsParam: boolean) {
        navigateTo(getSearchUrl(textParam, brandParam, useAnalogsParam));
    }

    function showBrands() {
        setShowBrandSuggestions(true);
        isFirstSearch.current = true;
        setDoFirstSearch(true);

        // Вызываем событие для изменения состояния хлебных крошек "Уточнение запроса"
        emit('toggleBrandSearch', {active: true});
    }

    function handleInputChange(value: SearchInputModel) {
        // console.log(`HomePage/handleInputChange/value: ${JSON.stringify(value)}`);
        setSearchText(value);
        setSearchBrand('');
        // console.log(`HomePage/handleInputChange/searchText: ${JSON.stringify(searchText)}`);
        if (searchText.cleanedValue === value.cleanedValue) return;
        setSuggestItems([]);
        setShowBrandSuggestions(false);
        emit('toggleBrandSearch', {active: false}); // Сбрасываем состояние хлебных крошек "Уточнение запроса"
    }

    function handleSearchClick() {
        startSearch();
    }

    function handleSearchHistorySelected(searchHistory: SearchRequest) {
        setSearchBrand(searchHistory.brand);
        setSearchText({
            originalValue: searchHistory.article,
            cleanedValue: searchHistory.article,
            needToSuggestBrands: false
        });
    }

    useEffect(() => {
        if (searchBrand && searchText) startSearch();
    }, [searchBrand, useAnalogs]);

    // Loading
    if (isLoading) {
        return (
            <div className={b()}>
                <div className={b('search-panel')}>
                    <SearchBar
                        size="l"
                        searchText={searchText}
                        searchBrand={searchBrand}
                        useAnalogs={useAnalogs}
                        onSearchClick={handleSearchClick}
                        onInputChange={handleInputChange}
                        onBrandSelected={(brand) => setSearchBrand(brand.name)}
                        onUseAnalogsChanged={(value) => setUseAnalogs(value)}
                        onBrandSuggestionsChanged={(items) => setSuggestItems(items)}
                        searchHistory={searchHistory}
                        onSearchHistorySelected={handleSearchHistorySelected}
                    />
                    <div className={b('loader')}>
                        <Loader size={'l'}></Loader>
                    </div>
                </div>
            </div>
        );
    }

    // Not found
    if (showBrandSuggestions && searchText.cleanedValue && suggestItems.length === 0) {
        return (
            <div className={b()}>
                <div className={b('search-panel')}>
                    <SearchBar
                        size="l"
                        searchText={searchText}
                        searchBrand={searchBrand}
                        useAnalogs={useAnalogs}
                        onSearchClick={handleSearchClick}
                        onInputChange={handleInputChange}
                        onBrandSelected={(brand) => setSearchBrand(brand.name)}
                        onUseAnalogsChanged={(value) => setUseAnalogs(value)}
                        onBrandSuggestionsChanged={(items) => setSuggestItems(items)}
                        searchHistory={searchHistory}
                        onSearchHistorySelected={handleSearchHistorySelected}
                    />
                    <div className={b('brand-not-found')}>
                        <NotFound showClearFilters={false} searchText={searchText.originalValue}></NotFound>
                    </div>
                </div>
            </div>
        );
    }

    // Show results
    return (
        <div className={b()}>
            <div className={b('search-panel')}>
                <SearchBar
                    size="l"
                    searchText={searchText}
                    searchBrand={searchBrand}
                    useAnalogs={useAnalogs}
                    onSearchClick={handleSearchClick}
                    onInputChange={handleInputChange}
                    onBrandSelected={(brand) => setSearchBrand(brand.name)}
                    onUseAnalogsChanged={(value) => setUseAnalogs(value)}
                    onBrandSuggestionsChanged={(items) => setSuggestItems(items)}
                    searchHistory={searchHistory}
                    onSearchHistorySelected={handleSearchHistorySelected}
                />
                <Link
                    href={'https://3803167321.laximo.pro/index.php?task=catalogs'}
                    className={b('catalog-link')}
                    target={'_blank'}
                >
                    Каталоги
                </Link>
                {showBrandSuggestions ?
                    suggestItems.length === 0 ?
                        (
                            <div className={b('brand-not-found')}>
                                <NotFound showClearFilters={false}></NotFound>
                            </div>
                        ) :
                        (
                            <div className={b('brand-suggestions')}>
                                {/* SectionHeader */}
                                <div className={b('brand-suggestions-header')}>
                                    {'Результаты поиска бренда'}
                                    <ChevronDown />
                                </div>
                                {/* SectionItems */}
                                <div className={b('brand-suggestions-items')}>
                                    {/* SectionItemsHeader */}
                                    <div className={b('brand-suggestions-header2')}>
                                        <div>Бренд</div>
                                        <div>Артикул</div>
                                        <div>Наименование</div>
                                    </div>
                                    {/* Item */}
                                    {suggestItems.map((x) => {
                                        return (
                                            <div
                                                key={x.name}
                                                className={b('brand-suggestions-item')}
                                                onClick={() => setSearchBrand(x.name)}
                                            >
                                                <div>{x.name}</div>
                                                <div>
                                                    <span className={b('brand-suggestions-article')}>
                                                        {searchText.cleanedValue}
                                                    </span>
                                                </div>
                                                <div>{x.articleName}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>) : null
                }
            </div>
        </div>
    );
};
